<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

defineProps<{
  itemIndex: number
}>()

const issueTableAlignCells = [
  'start',
  'start',
  'start',
  'start',
]
</script>

<template>
  <CfgTypography
    class="!font-highlighted"
    :class="{
      'text-left': issueTableAlignCells[itemIndex] === 'start',
      'text-right': issueTableAlignCells[itemIndex] === 'end',
      'flex justify-center text-center': issueTableAlignCells[itemIndex] === 'center',
      [`cell-${itemIndex + 1}`]: true,
    }"
    :size="CfgTypographySizes.md"
    tag-name="div"
  >
    <slot :align="issueTableAlignCells[itemIndex]" />
  </CfgTypography>
</template>

<style lang="scss" scoped>
$base-row-width: 900px;
$sizes: 180px, 120px, 160px, 120px, 100px, 100px, 100px, 100px, 140px, 140px;

[class*='cell-'] {
  @apply shrink-0;
}

.cell {
  @each $size in $sizes {
    $idx: index($list: $sizes, $value: $size);

    &-#{$idx} {
      @screen lg {
        width: $size * 1.1;
      }

      @media (min-width: 1100px) {
        width: $size * 1.2;
      }

      @screen xl {
        width: $size * 0.95;
      }

      @screen 2xl {
        width: $size;
      }
    }
  }
}
</style>
