<script lang="ts" setup>
import { CfgButton, CfgChip, CfgHint, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { isNil } from 'lodash-es'
import { ROW_MIN_HEIGHT } from '../constants'
import type { UpdatedIssue } from '~/components/Issue/Table/types'
import type { IssueBuyEmit } from '~/components/Issue/types'
import { IssueBuyUrlTypeEnum } from '~/components/Issue/types'

const props = withDefaults(defineProps<{
  issue: UpdatedIssue
  companyId?: number
  highlighted?: boolean
  detailsUrl: string
  orderUrl?: string
  soldOut?: boolean
  afterMaturityDate?: boolean
  compact?: boolean
  extraCompact?: boolean
  shownOn: 'issueDetails' | 'issuesListing'
  isIssuerIssue: boolean
  urlType: IssueBuyUrlTypeEnum | null
  titleLevel: 'h2' | 'h3'
  issuerIssueStatus?: {
    label: string
    good?: boolean
  }
  formattedNominalValue: string
  isLatest?: boolean
}>(), {
  orderUrl: '',
  highlighted: false,
  extraCompact: false,
  shownOn: 'issuesListing',
  isIssuerIssue: false,
  companyId: undefined,
  issuerIssueStatus: () => ({
    label: '',
    good: false,
  }),
  urlType: IssueBuyUrlTypeEnum.JAMES,
  isLatest: false,
})
const emit = defineEmits<{
  'buy:issue': [IssueBuyEmit]
  'click:issueDetail': []
}>()

const { t } = useI18n()

// composables
const { interestRate, interestRateTooltip } = useIssueVariableYield({
  variableYieldInterestRateType: props.issue.interestRate?.variableYieldInterestRateType,
  variableYieldMargin: props.issue.interestRate?.variableYieldMargin,
  annualInterestRate: props.issue.annualInterestRate,
  yieldType: props.issue.interestRate?.yieldType,
  annualInterestRateNote: props.issue.interestRate?.annualInterestRateNote,
})

const updatedIssue = ref(props.issue)
const investedPercent = useIssueInvestedPercent(updatedIssue)

const orderButtonLabel = computed(() => props.urlType === IssueBuyUrlTypeEnum.AFFILIATE ? t('bondList.affiliate') : t('bondList.cta'))
const companyName = computed(() => props.issue?.company?.data?.attributes?.name || props.issue?.company?.name || '-')
const showBuyButton = computed(() => props.issue?.showBuyButton !== false)

function handleIssueBuy(issue: UpdatedIssue) {
  if (!props.urlType) {
    return
  }

  emit('buy:issue', { issue, url: { orderUrl: props.orderUrl, urlType: props.urlType } })
}

function handleIssueDetailNavigation() {
  emit('click:issueDetail')
}

const { openContactIssuerForwardDialog } = useContactIssuerForward()
</script>

<template>
  <CfgChip
    v-if="isLatest && isIssuerIssue"
    class="absolute left-4 top-[-0.9rem] z-[1] !bg-brand-light !px-4 !py-1 text-brand"
  >
    <CfgTypography
      class="!text-[0.75rem]"
      :size="CfgTypographySizes.sm"
      tag-name="div"
    >
      {{ $t('bondList.newest') }}
    </CfgTypography>
  </CfgChip>

  <div
    class="issue-row flex items-center justify-between rounded-xl bg-white pl-4 pr-2"
    data-cy="homepage-issue-link"
    :style="{
      height: `${ROW_MIN_HEIGHT}px`,
    }"
  >
    <NuxtLink
      class="flex max-w-full !flex-1"
      :class="{
        'justify-between gap-x-1': extraCompact,
        'justify-between gap-x-[2%]': compact,
        'gap-x-5': !compact && !extraCompact,
        'pt-4': isLatest,
      }"

      :to="{
        path: detailsUrl,
        query: {
          ...(companyId && { companyId }),
        },
      }"
      @click="handleIssueDetailNavigation"
      @click.middle="handleIssueDetailNavigation"
    >
      <!-- Název emitenta -->
      <IssueTableCell
        class="content-center items-center"
        :item-index="extraCompact ? 8 : 0"
      >
        <!--  {{ issue?.issueDate }} -->
        <CfgTypography
          class="line-clamp-1 !font-highlighted text-brand underline"
          :class="{ '!leading-6': isIssuerIssue, 'line-clamp-2': extraCompact }"
          data-cy="homepage-issue-title"
          :tag-name="titleLevel"
        >
          {{ companyName }}
        </CfgTypography>

        <!-- ISIN -->
        <CfgTypography
          class="block"
          :size="CfgTypographySizes.sm"
          tag-name="small"
        >
          ISIN: {{ issue.isin }}
        </CfgTypography>

        <span v-if="shownOn !== 'issueDetails'" class="mt-1 block">
          <IssueBusinessCategoryBadge :business-category="issue?.company?.businessCategory || null" />
        </span>
      </IssueTableCell>

      <!-- Stav dluhopisu  -->
      <IssueTableCell
        v-if="shownOn === 'issueDetails' && isIssuerIssue"
        :class="{
          '!text-red': issuerIssueStatus.good === false,
          '!text-success': issuerIssueStatus.good === true,
          '!text-grey-500': issuerIssueStatus.good === undefined,
        }"
        :item-index="1"
      >
        <CfgTypography
          class="block"
          :size="CfgTypographySizes.sm"
          tag-name="small"
        >
          {{ issuerIssueStatus.label }}
        </CfgTypography>
      </IssueTableCell>

      <!-- Úrok p.a. -->
      <IssueTableCell
        class="min-w-[50px]"
        :item-index="extraCompact ? 6 : (!compact ? 1 : 6)"
      >
        <div class="flex">
          <ClientOnly>
            <CfgHint
              class="-mt-1 h-fit p-0"
              :disabled="!interestRateTooltip"
              hide-icon
              interactive
            >
              <template #trigger>
                <div class="flex h-4">
                  <CfgTypography
                    class="mt-1 block w-min text-center !font-extrabold"
                    :html="interestRate"
                    :size="CfgTypographySizes.md"
                    tag-name="div"
                  />

                  <span v-if="interestRateTooltip">
                    *
                  </span>
                </div>
              </template>

              <template #content="{ initialized }">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-if="initialized" v-html="interestRateTooltip" />
              </template>
            </CfgHint>

            <template #fallback>
              <div class="flex">
                <CfgTypography
                  class="mt-1 block w-min text-center !font-extrabold"
                  :html="interestRate"
                  :size="CfgTypographySizes.md"
                  tag-name="div"
                />

                <span v-if="interestRateTooltip">
                  *
                </span>
              </div>
            </template>
          </ClientOnly>
        </div>

        <CfgTypography
          class="block"
          :size="CfgTypographySizes.sm"
        >
          {{ issue.returnsPeriodicity ?? '-' }}
        </CfgTypography>
      </IssueTableCell>

      <!-- Score -->
      <IssueTableCell
        v-if="!compact && !isIssuerIssue"
        :item-index="extraCompact ? 7 : 2"
      >
        <div class="block">
          <IssueScore :collaterals="issue.collaterals" :dmat-checks="issue.dmatChecks" />

          <CfgTypography
            class="mt-1 block"
            :size="CfgTypographySizes.sm"
            tag-name="span"
          >
            {{ $t('bondList.collateral.count', issue?.dmatChecks || 0) }}
          </CfgTypography>
        </div>

        <!-- mfcrScore -->
        <CfgTypography
          v-if="!extraCompact && !compact"
          class="mt-1 block text-secondary"
          :size="CfgTypographySizes.sm"
          tag-name="small"
        >
          <template v-if="!isNil(issue.scoreData?.score)">
            {{ $t('bondList.table.head.mfcrScore.description', issue.scoreData!.score!) }}
          </template>

          <template v-else>
            -
          </template>
        </CfgTypography>
      </IssueTableCell>

      <!-- Splatnost -->
      <IssueTableCell
        class="flex flex-col"
        :class="extraCompact ? 'w-[8.4rem]' : ''"
        :item-index="extraCompact || !compact ? 9 : 0"
      >
        <IssueTableCellsMaturity
          v-if="issue"
          :after-maturity-date="afterMaturityDate"
          :extra-compact="extraCompact"
          :has-early-repayment="issue.hasEarlyRepayment"
          :invested-percent="investedPercent"
          :issue-maturity-date="issue.maturityDate"
          :nominal-value="formattedNominalValue"
          :sold-out="soldOut"
        />
      </IssueTableCell>
    </NuxtLink>

    <div
      v-if="orderUrl && showBuyButton"
      class="mr-5 flex max-w-[160px] grow flex-col gap-y-1.5"
      :class="{
        'pl-[5%]': compact,
        'pl-[2.5%]': !compact,
      }"
    >
      <CfgButton
        class="break-line block w-full pb-1.5 pt-1 text-center text-[12px]"
        data-cy="homepage-issue-order-link"
        flat
        type="button"
        @click.stop="handleIssueBuy(issue)"
      >
        <span class="text-[12px] leading-none">
          {{ orderButtonLabel }}
        </span>
      </CfgButton>

      <NuxtLink
        class="hidden lg:block"
        data-cy="issueLink"
        :to="{
          path: detailsUrl,
          query: {
            ...(companyId && { companyId }),
          },
        }"
        @click="handleIssueDetailNavigation"
        @click.middle="handleIssueDetailNavigation"
      >
        <CfgButton
          as="span"
          class="w-full"
          flat
          stroke
          variant="primary"
        >
          <span class="block !py-1.5 px-2 text-center text-[12px]">
            {{ $t('common.details') }}
          </span>
        </CfgButton>
      </NuxtLink>
    </div>

    <div
      v-else
      class="mr-5 grid max-w-[160px] grow items-end gap-x-1.5 gap-y-1"
      :class="{
        'pl-[5%]': compact,
        'pl-[2.5%]': !compact,
      }"
    >
      <CfgButton
        v-if="issue.top"
        class="break-line block w-full pb-1.5 pt-1 text-center text-[12px]"
        flat
        type="button"
        @click.stop="openContactIssuerForwardDialog({ companyName: issue.company?.name || null,
                                                      issueName: issue.name,
                                                      issueSlug: issue.slug || null,
        })"
      >
        <span class="text-[12px] leading-none">
          {{ $t('contact.issuerForwardDialog.button.contact.label') }}
        </span>
      </CfgButton>

      <NuxtLink
        class="w-full"
        :to="{
          path: detailsUrl,
          query: {
            ...(companyId && { companyId }),
          },
        }"
        @click="handleIssueDetailNavigation"
        @click.middle="handleIssueDetailNavigation"
      >
        <CfgButton
          as="span"
          class="w-full"
          flat
          stroke
          variant="primary"
        >
          <span class="block !py-1.5 px-2 text-center text-[12px]">
            {{ $t('common.details') }}
          </span>
        </CfgButton>
      </NuxtLink>
    </div>
  </div>

  <CfgTypography
    v-if="(issue.top || 0 > 1) && !soldOut && !afterMaturityDate && !compact"
    class="absolute right-1.5 top-[90px] origin-top-right rotate-90 !text-[10px] text-grey-300"
    data-nosnippet
    :size="CfgTypographySizes.small"
    tag-name="span"
  >
    {{ $t('bondList.sponsored.label') }}
  </CfgTypography>
</template>

<style lang="scss" scoped>
.issue-row {
  @apply border border-grey-stroke lg:border-none;

  @screen lg {
    --border-color: theme('colors.grey.stroke');
    --bg: theme('colors.white');

    &.border-brand {
      --border-color: theme('colors.brand.DEFAULT');
    }

    &.bg-primary {
      --bg: theme('backgroundImage.primary')
    }

    @include hover-scale-bg(var(--bg), true, 0.35s) {
      border: 1px solid var(--border-color);
    }
  }
}
</style>
