export const suffixKeys = [
  '',
  'common.numberAbbreviation.thousand',
  'common.numberAbbreviation.million',
  'common.numberAbbreviation.billion',
  'common.numberAbbreviation.trillion',
  'common.numberAbbreviation.e',
  'common.numberAbbreviation.e',
]

export type useShortcutAmountOutput = {
  value: number
  suffixKey: string
}

// todo make test
export function useAbbreviationNumber(
  num: number,
): useShortcutAmountOutput {
  const output: useShortcutAmountOutput = {
    value: num,
    suffixKey: '',
  }

  if (num < 100000) {
    return output
  }

  // handle negatives
  output.value = Math.abs(num)

  // what tier? (determines SI symbol)
  const tier = (Math.log10(num) / 3) | 0

  if (tier === 0) {
    return output
  }

  const suffix = suffixKeys[tier]

  const scale = 10 ** (tier * 3)

  // scale the number
  const scaled = num / scale

  const rounded = scaled.toFixed(0)

  output.value = Number(rounded)
  output.suffixKey = suffix ?? ''

  // format number and add suffix
  return output
}
