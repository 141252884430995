<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

const props = defineProps<{
  issueMaturityDate: string
  hasEarlyRepayment?: boolean
  soldOut?: boolean
  afterMaturityDate?: boolean
  investedPercent?: number
  nominalValue?: string
  maturityTypographySize?: CfgTypographySizes
  extraCompact?: boolean
}>()

const showInvestedPercent = computed(() => !props.soldOut && Number(props.investedPercent) > 0 && Number(props.investedPercent) <= 100)
</script>

<template>
  <CfgTypography
    class="block whitespace-nowrap !font-highlighted"
    :size="maturityTypographySize ? maturityTypographySize : CfgTypographySizes.md"
  >
    <template v-if="afterMaturityDate">
      {{ issueMaturityDate }} <span v-if="!extraCompact">
        / {{ $t('bondList.afterMaturityDate.label') }} </span>
    </template>

    <template v-else-if="soldOut">
      {{ issueMaturityDate }} <span v-if="!extraCompact"> /
        <span class="text-red">{{ $t('bondList.soldOut.label') }}</span>
      </span>
    </template>

    <template v-else-if="hasEarlyRepayment">
      {{ issueMaturityDate }} <span v-if="!extraCompact"> /

        {{ $t('bondList.hasEarlyRepayment.shortLabel') }} </span>
    </template>

    <template v-else>
      {{ issueMaturityDate }}
    </template>
  </CfgTypography>

  <CfgTypography
    v-if="nominalValue"
    :size="CfgTypographySizes.sm"
    tag-name="small"
  >
    {{ $t('common.date.from', { from: nominalValue }) }}
  </CfgTypography>

  <CfgTypography
    v-if="showInvestedPercent"
    class="block !font-highlighted text-success"
    :size="CfgTypographySizes.sm"
    tag-name="small"
  >
    {{ $t('bondList.investedPercent.label', investedPercent || 0) }}
  </CfgTypography>

  <template v-else-if="soldOut && extraCompact">
    <span>
      <span class="text-red">{{ $t('bondList.soldOut.label') }}</span>
    </span>
  </template>
</template>
