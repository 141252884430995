<script lang="ts" setup>
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { getIssueOrderUrl } from './helpers'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { AvailableIssueRenderModes } from '~/components/Issue/Table/Body/Item/types'
import type { UpdatedIssue } from '~/components/Issue/Table/types'
import { type IssueBuyEmit, IssueBuyUrlTypeEnum } from '~/components/Issue/types'
import { useAbbreviationNumber } from '~/composables/useAbbreviationNumber'
import { ISSUE_URL } from '~/constants/issue'
import { isOurIssue as isOurIssueFunction } from '~/helpers/issue/conditions'
import type { MaybeDeepReadonly } from '~/types/helpers'

const props = withDefaults(defineProps<{
  issue?: MaybeDeepReadonly<ShortIssueWithId>
  loading?: boolean
  renderMode?: AvailableIssueRenderModes
  canBeHighlighted?: boolean
  compact?: boolean
  extraCompact?: boolean
  shownOn?: 'issueDetails' | 'issuesListing'
  isIssuerIssue?: boolean
  titleLevel?: 'h2' | 'h3'
  isLatest?: boolean
  companyId?: number
}>(), {
  issue: undefined,
  loading: false,
  renderMode: AvailableIssueRenderModes.responsive,
  canBeHighlighted: false,
  extraCompact: false,
  shownOn: 'issuesListing',
  isIssuerIssue: false,
  titleLevel: 'h3',
  isLatest: false,
  companyId: undefined,
})

defineEmits<{
  'buy:issue': [data: IssueBuyEmit]
  'click:issueDetail': []
}>()

dayjs.extend(relativeTime)

// COMPOSABLES
const { t } = useI18n()

const responsive = useResponsive()

// COMPUTED
const highlighted = computed(() => props.canBeHighlighted && props?.issue?.top && props.issue.top >= 0)

const updatedIssue = computed<UpdatedIssue | null>(() => {
  if (!props.issue) {
    return null
  }

  const { value, suffixKey } = useAbbreviationNumber(
    props.issue?.nominalValue ?? 0,
  )

  const date = (props.issue?.maturityDate || props.issue?.payoutDate)

  const issueMaturityDate = date
    ? dayjs(date).fromNow()
    : '-'

  return {
    ...(props.issue && props.issue),
    annualInterestRate: !props.issue || !props.issue?.interestRate?.annualInterestRate
      ? ['-']
      : props.issue?.interestRate?.annualInterestRate?.toFixed(2)?.split?.('.') || ['0', '0'],

    maturityDate: issueMaturityDate,

    nominalValue: {
      formattedValue: new Intl.NumberFormat().format(value),
      value: props.issue?.nominalValue || 0,
      suffix: t(suffixKey),
      currency: t(`common.currencies.${props.issue?.currency}`),
    },
    returnsPeriodicity: props.issue?.returnsPeriodicity,
    collateralTypes: props.issue.collaterals,
    totalAmount: props.issue.totalAmount,
    availableCount: props.issue.availableCount,
    showBuyButton: props.issue.showBuyButton,
  } satisfies MaybeDeepReadonly<UpdatedIssue>
})

const detailsUrl = computed(() => (updatedIssue.value ? `/${ISSUE_URL}/${updatedIssue.value.slug}` : null))

const orderUrl = computed(() => getIssueOrderUrl(updatedIssue.value))

const urlType = computed(() => {
  if (props.issue?.jamesOrderFormURL) {
    return IssueBuyUrlTypeEnum.JAMES
  }

  return null
})

const afterMaturityDate = computed(() => {
  if (props.issue?.maturityDate) {
    return new Date(props.issue.maturityDate) <= new Date()
  }

  if (props.issue?.payoutDate) {
    return new Date(props.issue.payoutDate) <= new Date()
  }

  return false
})

const soldOut = computed(() => {
  return updatedIssue.value?.isAvailable === false
})

type IssuerIssueReturnStatus = {
  label: string
  good?: boolean
}

const issuerIssueReturnStatus = computed((): IssuerIssueReturnStatus => {
  const status: IssuerIssueReturnStatus = {
    label: t('bondList.issuerIssues.noInfo'),
    good: undefined,
  }

  if (!props.issue) {
    return status
  }

  if (isOurIssueFunction(props.issue)) {
    status.label = afterMaturityDate.value ? t('bondList.issuerIssues.successfullyPaid') : t('bondList.issuerIssues.paymentOngoing')
    status.good = true
  }

  return status
})

const formattedNominalValue = computed(() => {
  return updatedIssue.value ? `${updatedIssue.value.nominalValue.formattedValue} ${updatedIssue.value.nominalValue.suffix} ${updatedIssue.value.nominalValue.currency}` : '-'
})
</script>

<template>
  <div>
    <!-- SKELETON -->
    <ClientOnly v-if="loading">
      <div class="w-full">
        <LazyIssueTableBodyItemRowSkeleton v-if="responsive.desktop || responsive.desktop === undefined" />

        <LazyIssueTableBodyItemCardSkeleton v-else />
      </div>
    </ClientOnly>

    <!-- CONTENT -->
    <div v-else-if="updatedIssue" class="relative flex w-full max-w-full flex-1 lg:grow">
      <template v-if="renderMode === AvailableIssueRenderModes.responsive">
        <!-- Desktop version -->
        <div v-if="!responsive.mobile" class="hidden w-full lg:block">
          <IssueTableBodyItemRow
            :after-maturity-date="afterMaturityDate"
            :compact="compact"
            :company-id="companyId || issue?.company.id"
            :details-url="detailsUrl || ''"
            :extra-compact="extraCompact"
            :formatted-nominal-value="formattedNominalValue"
            :highlighted="highlighted || false"
            :is-issuer-issue="isIssuerIssue"
            :is-latest="isLatest"
            :issue="updatedIssue"
            :issuer-issue-status="issuerIssueReturnStatus"
            :order-url="orderUrl || ''"
            :shown-on="shownOn"
            :sold-out="soldOut"
            :title-level="titleLevel"
            :url-type="urlType"
            @buy:issue="$emit('buy:issue', $event)"
            @click:issue-detail="$emit('click:issueDetail')"
          />
        </div>

        <!-- Mobile version SSR rendering for high performance in phones -->
        <div class="flex flex-1 lg:hidden">
          <IssueTableBodyItemCard
            :after-maturity-date="afterMaturityDate"
            :company-id="companyId || issue?.company?.data?.id"
            :details-url="detailsUrl || ''"
            :formatted-nominal-value="formattedNominalValue"
            :highlighted="highlighted || false"
            :is-issuer-issue="isIssuerIssue"
            :is-latest="isLatest"
            :issue="updatedIssue"
            :issuer-issue-status="issuerIssueReturnStatus"
            :order-url="orderUrl || ''"
            :shown-on="shownOn"
            :sold-out="soldOut"
            :title-level="titleLevel"
            :url-type="urlType"
            @buy:issue="$emit('buy:issue', $event)"
            @click:issue-detail="$emit('click:issueDetail')"
          />
        </div>
      </template>

      <IssueTableBodyItemCard
        v-else-if="renderMode === AvailableIssueRenderModes.card"
        :after-maturity-date="afterMaturityDate"
        :company-id="companyId || issue?.company?.data?.id"
        :details-url="detailsUrl || ''"
        :formatted-nominal-value="formattedNominalValue"
        :highlighted="highlighted || false"
        :is-issuer-issue="isIssuerIssue"
        :is-latest="isLatest"
        :issue="updatedIssue"
        :issuer-issue-status="issuerIssueReturnStatus"
        :order-url="orderUrl || ''"
        :shown-on="shownOn"
        :sold-out="soldOut"
        :title-level="titleLevel"
        :url-type="urlType"
        @buy:issue="$emit('buy:issue', $event)"
        @click:issue-detail="$emit('click:issueDetail')"
      />

      <IssueTableBodyItemRow
        v-else-if="renderMode === AvailableIssueRenderModes.row"
        :after-maturity-date="afterMaturityDate"
        :compact="compact"
        :company-id="companyId || issue?.company?.data?.id"
        :details-url="detailsUrl || ''"
        :formatted-nominal-value="formattedNominalValue"
        :highlighted="highlighted || false"
        :is-issuer-issue="isIssuerIssue"
        :is-latest="isLatest"
        :issue="updatedIssue"
        :order-url="orderUrl || ''"
        :shown-on="shownOn"
        :sold-out="soldOut"
        :title-level="titleLevel"
        :url-type="urlType"
        @buy:issue="$emit('buy:issue', $event)"
        @click:issue-detail="$emit('click:issueDetail')"
      />
    </div>
  </div>
</template>
