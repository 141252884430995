<script lang="ts" setup>
import { CfgButton, CfgChip, CfgHint, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import type { UpdatedIssue } from '~/components/Issue/Table/types'
import type { IssueBuyEmit } from '~/components/Issue/types'
import { IssueBuyUrlTypeEnum } from '~/components/Issue/types'
import SanitizedHTML from '~/components/base/SanitizedHTML.vue'

const props = withDefaults(defineProps<{
  issue: UpdatedIssue
  detailsUrl: string
  companyId?: number
  orderUrl?: string
  highlighted?: boolean
  soldOut?: boolean
  afterMaturityDate?: boolean
  shownOn: 'issueDetails' | 'issuesListing'
  isIssuerIssue: boolean
  urlType: IssueBuyUrlTypeEnum | null
  titleLevel?: 'h2' | 'h3'
  issuerIssueStatus?: {
    label: string
    good?: boolean
  }
  formattedNominalValue: string
  isLatest?: boolean
}>(), {
  orderUrl: '',
  companyId: undefined,
  highlighted: false,
  shownOn: 'issuesListing',
  isIssuerIssue: false,
  issuerIssueStatus: () => ({
    label: '',
    good: false,
  }),
  urlType: IssueBuyUrlTypeEnum.JAMES,
  isLatest: false,
})
const emit = defineEmits<{
  'click:issueDetail': [issue: UpdatedIssue]
  'buy:issue': [data: IssueBuyEmit]
}>()

const { issue } = toRefs(props)

const investedPercent = useIssueInvestedPercent(issue)

const { interestRate, interestRateDescription, interestRateTooltip } = useIssueVariableYield({
  variableYieldInterestRateType: props.issue.interestRate?.variableYieldInterestRateType,
  variableYieldMargin: props.issue.interestRate?.variableYieldMargin,
  annualInterestRate: props.issue.annualInterestRate,
  yieldType: props.issue.interestRate?.yieldType,
  annualInterestRateNote: props.issue.interestRate?.annualInterestRateNote,
})

const { t } = useI18n()
const collateralCaption = computed(() => t('bondList.collateral.count', props.issue?.dmatChecks || 0))

function handleIssueBuy(issue: UpdatedIssue) {
  if (!props.urlType) {
    return
  }

  emit('buy:issue', { issue, url: { orderUrl: props.orderUrl, urlType: props.urlType } })
}

function handleIssueDetailNavigation() {
  emit('click:issueDetail', props.issue)
}

const companyName = computed(() => props.issue?.company?.data?.attributes?.name || props.issue?.company?.name || '-')
const showBuyButton = computed(() => props.issue?.showBuyButton !== false)

const { openContactIssuerForwardDialog } = useContactIssuerForward()
</script>

<template>
  <div class="w-full">
    <CfgChip
      v-if="isLatest && isIssuerIssue"
      class="z-1 absolute left-4 top-[-0.9rem] !bg-brand-light !px-4 !py-1 text-brand"
    >
      <CfgTypography
        class="!text-[0.75rem]"
        :size="CfgTypographySizes.sm"
        tag-name="div"
      >
        {{ $t('bondList.newest') }}
      </CfgTypography>
    </CfgChip>

    <article class="flex w-full flex-col rounded-xl border border-grey-stroke p-5">
      <NuxtLink
        class="w-full grow"
        :to="{
          path: detailsUrl,
          query: {
            ...(companyId && { companyId }),
          },
        }"
        @click="handleIssueDetailNavigation"
      >
        <header class="grid grid-cols-2 items-center">
          <div>
            <CfgTypography
              v-if="(issue.top || 0 > 1) && !soldOut && !afterMaturityDate"
              class="mb-1 block text-grey-300"
              data-nosnippet
              :size="CfgTypographySizes.micro"
              tag-name="span"
            >
              {{ $t('bondList.sponsored.label') }}
            </CfgTypography>

            <CfgTypography
              class="line-clamp-2 !font-highlighted text-brand underline"
              :size="CfgTypographySizes.md"
              :tag-name="titleLevel"
            >
              {{ companyName }}
            </CfgTypography>

            <CfgTypography
              class="mt-1.5"
              :size="CfgTypographySizes.small"
              tag-name="div"
            >
              ISIN: {{ issue.isin }}
            </CfgTypography>
          </div>

          <div v-if="!isIssuerIssue && issue.collaterals" class="text-end">
            <ClientOnly>
              <IssueScore
                :collaterals="issue.collaterals"
                :dmat-checks="issue.dmatChecks"
                @click.stop
              />
            </ClientOnly>

            <CfgTypography class="mb-2.5 mt-1.5 block text-[12px]" :size="CfgTypographySizes.micro">
              {{ collateralCaption }}
            </CfgTypography>
          </div>

          <span v-if="shownOn !== 'issueDetails'" class="col-span-2 mt-2.5 block pb-1">
            <IssueBusinessCategoryBadge :business-category="issue?.company?.businessCategory || null" />
          </span>
        </header>

        <div class="mt-2">
          <ul class="divide-y divide-grey-stroke">
            <li v-if="isIssuerIssue" class="flex py-1.5">
              <CfgTypography class="grow text-grey-400" :size="CfgTypographySizes.xs">
                {{ $t('bondList.table.head.status.title') }}
              </CfgTypography>

              <CfgTypography
                class="!font-highlighted"
                :class="{
                  '!text-red': issuerIssueStatus.good === false,
                  '!text-success': issuerIssueStatus.good === true,
                  '!text-grey-500': issuerIssueStatus.good === undefined,
                }"
                :size="CfgTypographySizes.md"
              >
                {{ issuerIssueStatus.label }}
              </CfgTypography>
            </li>

            <li class="flex py-1.5">
              <CfgTypography class="grow text-grey-400" :size="CfgTypographySizes.xs">
                {{ $t('bondList.table.head.interestRate.title') }}
              </CfgTypography>

              <CfgTypography
                class="w-min !font-highlighted"
                :size="CfgTypographySizes.sm"
                tag-name="div"
              >
                <div class="flex justify-start gap-1.5">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span class="block grow text-end" v-html="interestRate" />

                  <ClientOnly>
                    <CfgHint
                      v-if="interestRateTooltip"
                      class="-mt-1 h-4 p-0"
                      hide-icon
                      interactive
                    >
                      <template #trigger>
                        *
                      </template>

                      <template #content="{ initialized }">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <p v-if="initialized" v-html="interestRateTooltip" />
                      </template>
                    </CfgHint>
                  </ClientOnly>
                </div>

                <!-- eslint-disable-next-line vue/no-v-html -->
                <SanitizedHTML
                  v-if="interestRateDescription"
                  by-tag-name="small"
                  :html="interestRateDescription"
                >
                  <template #default="{ html }">
                    <small
                      class="block text-grey-400"
                      v-html="html"
                    />
                  </template>
                </SanitizedHTML>
              </CfgTypography>
            </li>

            <li class="flex py-3">
              <CfgTypography class="my-auto grow text-grey-400" :size="CfgTypographySizes.sm">
                {{ $t('bondList.table.head.nominalValue.title') }}
              </CfgTypography>

              <CfgTypography class="text-end !font-highlighted" :size="CfgTypographySizes.sm">
                {{ $t('common.date.from', { from: formattedNominalValue }) }}
              </CfgTypography>
            </li>

            <li class="flex py-3">
              <CfgTypography class="my-auto grow text-grey-400" :size="CfgTypographySizes.sm">
                {{ $t('bondList.table.head.maturityDate.titleCard') }}
              </CfgTypography>

              <CfgTypography class="text-end !font-highlighted" :size="CfgTypographySizes.sm">
                <IssueTableCellsMaturity
                  v-if="issue"
                  :after-maturity-date="afterMaturityDate"
                  :has-early-repayment="issue.hasEarlyRepayment"
                  :invested-percent="investedPercent"
                  :issue-maturity-date="issue.maturityDate"
                  :maturity-typography-size="CfgTypographySizes.sm"
                  :sold-out="soldOut"
                />
              </CfgTypography>
            </li>
          </ul>
        </div>
      </NuxtLink>

      <footer class="relative flex w-full items-center justify-center gap-2 pt-2">
        <template v-if="orderUrl && showBuyButton">
          <div class="flex w-full gap-3">
            <NuxtLink
              class="!p-0"
              :to="{
                path: detailsUrl,
                query: {
                  ...(companyId && { companyId }),
                },
              }"
              @click="handleIssueDetailNavigation"
            >
              <CfgButton
                as="span"
                class="!flex h-full min-w-[90px] flex-1 items-center justify-center rounded-xl min-[370px]:min-w-[120px]"
                stroke
                variant="primary"
              >
                <span class="block text-center text-[14px]">{{ $t('common.details') }}</span>
              </CfgButton>
            </NuxtLink>

            <CfgButton
              class="grow rounded-xl py-5 text-center text-[14px]"
              flat
              type="button"
              variant="primary"
              @click="handleIssueBuy(issue)"
            >
              <template v-if="urlType === 'james'">
                {{ $t('bondList.cta') }}
              </template>

              <template v-else>
                {{ $t('bondList.affiliate') }}
              </template>
            </CfgButton>
          </div>
        </template>

        <div
          v-else
          class="flex w-full gap-x-2"
        >
          <NuxtLink
            class="block text-center"
            :class="{
              'w-full': !issue.top,
            }"
            :to="{
              path: detailsUrl,
              query: {
                ...(companyId && { companyId }),
              },
            }"
            @click="handleIssueDetailNavigation"
          >
            <CfgButton
              v-if="detailsUrl"
              as="span"
              class="w-full rounded-xl !text-md !leading-none"
              stroke
              variant="primary"
            >
              {{ $t('common.details') }}
            </CfgButton>
          </NuxtLink>

          <CfgButton
            v-if="issue.top"
            class="w-full"
            flat
            type="button"
            @click.stop="openContactIssuerForwardDialog({ companyName: issue.company?.name || null,
                                                          issueName: issue.name,
                                                          issueSlug: issue.slug || null,
            })"
          >
            {{ $t('contact.issuerForwardDialog.button.contact.label') }}
          </CfgButton>
        </div>
      </footer>
    </article>
  </div>
</template>

<style lang="scss" scoped>
.issue-card {
  @media (any-hover: hover) {
    @apply transition-all duration-300 hover:-translate-y-2 hover:shadow-dark/10;
  }
}
</style>
